import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import PageHeadingTitle from "@components/pageHeadingTitle"

const UsedEquipment = ({ data }) => {
  const usedEquipment = data.allSanityUsedequipment.nodes

  return (
    <Layout>
      <SEO
        title="Used Equipment"
        description={data.site.siteMetadata.description}
      />
      <div className="page-headline">
        <div className="container">
          <PageHeadingTitle h1={"Equipment"} h2={"Used Equipment"}/>
        </div>
      </div>
      <div className="page-body">
        <div className="container">
          {/*<BlockContent
                    blocks={page._rawBody || []}
                    serializers={serializers}
                  />*/}
          <p>
            Below is a list of the quality used equipment that we have available
            at Fabricating Equipment Sales Co. Our used equipment is available
            for shipment anywhere. Just give us a call for shipping details and
            rates. Feel free to make an offer on any of our products, or give us
            a call at <a href="tel:303-466-7341">303-466-7341</a> or
            <a href="tel:303-550-0734"> 303-550-0734</a> for further
            information.
          </p>
          <div className="my-5 used-equip">
            <div className="row">
              {usedEquipment.map((equipment, index) => (
                // <div className="col-md-6" key={index}>
                //   <Link to={equipment.slug.current}>
                //     <div
                //       className="kingsland shadow"
                //       style={{
                //         background: `url(${equipment?.banner?.asset?.fluid?.src}) no-repeat`,
                //         backgroundSize: "cover",
                //         height: "310px",
                //         width: "100%",
                //       }}
                //     >
                //       <div className="equip-product shadow p-4">
                //         <h3 className="text-center">{equipment.name}</h3>
                //         <span>{`$${equipment.price}`}</span>
                //       </div>
                //     </div>
                //   </Link>
                // </div>

                <div
                  className="col-md-6 mb-5 text-left"
                  key={"type.slug.current"}
                >
                  <div className="blog-item bg-light rounded border">
                    <Link to={equipment.slug.current}>
                      <div className="blog-img-tile">
                        <div className="tile-bg-wrapper">
                          <span className="doc-icon">
                            <i className="fa fa-file-text"></i>
                          </span>
                          <div
                            className="tile-bg rounded-top"
                            style={{
                              backgroundImage: `url(${(equipment &&
                                equipment.banner &&
                                equipment.banner.asset &&
                                equipment.banner.asset.fluid &&
                                equipment.banner.asset.fluid.src) ||
                                require("@images/no-image-placeholder.jpg")})`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </Link>
                    <div className="equip-product mt-4 px-3">
                      <Link to={equipment.slug.current}>
                        <h3>{equipment.name}</h3>
                      </Link>
                      <p className="mt-2 mb-0">{`$` + equipment.price}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default UsedEquipment

export const usedEquipmentQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityUsedequipment {
      nodes {
        id
        name
        slug {
          current
        }
        banner {
          asset {
            fluid {
              src
            }
          }
        }
        price
      }
    }
  }
`
